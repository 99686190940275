:root {
  --accent: #{op.$violet-7};
  --link: #{op.$violet-10};
  --link-higher: #{op.$violet-8};
  --logo: url("../img/bms/logo.svg");
  --bs-primary: #{op.$violet-10};
}

// sass vars
$enable-rounded: false;
$enable-gradients: true;
$navbar-button-text: false;
$primary: hsl(255 52.7% 43.9%); // Open Props violet-10
